import { Card } from "react-bootstrap";
import styled from "styled-components";

export const UploadCard = styled(Card)`
  cursor: pointer;
  margin: 20px 0;
  background: #121419;
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

export const UploadCardImage = styled(Card.Img)`
  object-fit: cover;
  max-height: 200px;
  height: 180px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

export const UploadCardBody = styled(Card.Body)`
  padding: 5px 8px;
`;

export const UploadCardTitle = styled(Card.Title)`
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  color: #f1f1f1;
  margin-bottom: 2px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const UploadCardText = styled(Card.Text)`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
