import React from "react";
import { Container, Nav } from "react-bootstrap";
import { Outlet } from "react-router-dom";

import "./App.css";

export const App: React.FC = () => {
  return (
    <Container className="media-wrapper" fluid>
      <Container>
        <h3 className="text-center" style={{ color: "#fff" }}>
          Software Demonstration: Beta, Phase I
        </h3>
        <Nav className="justify-content-center">
          <Nav.Item>
            <Nav.Link
              href="/"
              style={{ color: "#fff", textDecoration: "underline" }}
            >
              Media (VOD)
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="/live-events"
              style={{ color: "#fff", textDecoration: "underline" }}
            >
              Live Events
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <div id="cembooClientJoinNowButton"></div>
          </Nav.Item>
        </Nav>
      </Container>

      <Outlet />
    </Container>
  );
};
